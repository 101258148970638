import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom'
import p_logo from '../assets/images/Passepartout_logo.svg'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Navigation = () => {
	return (
		<Navbar collapseOnSelect id='navigation' expand='md'>
			<Navbar.Brand href="/">
				<img width="200" src={p_logo} alt="passepartout-logo" className='nav__logo' />
			</Navbar.Brand>

			<Navbar.Toggle aria-controls='basic-navbar-nav'>
				<FontAwesomeIcon icon={faBars} />
			</Navbar.Toggle>

			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='nav__collapse'>
					<Nav.Link eventKey='1' as={NavLink} end to='/about'>Om oss</Nav.Link>
					<Nav.Link eventKey='2'  as={NavLink} end to='/contact'>Kontakt</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default Navigation
