import Navbar from 'react-bootstrap/Navbar'

const Footer = () => {
	return (
		<Navbar id='footer' fixed='bottom'>
			<div className="footer__item">
				<a href="mailto:info@passepartoutlund.se" className="button button__footer">Maila oss</a>
			</div>
		</Navbar>
	)
}

export default Footer