import SingleImage from '../components/SingleImage'
//import SingleText from '../components/SingleText'
import TextImage from '../components/TextImage'

const HomePage = () => {
	return (
		<>
			{TextImage && <TextImage />}
			{SingleImage && <SingleImage />}
			{/*{SingleText && <SingleText />}*/}
		</>
	)
}

export default HomePage