
const AboutPage = () => {
	return (
		<div className='single-text about'>
			<h2>Passepartout Lund AB</h2>
			<p>Skyttelinjen 95</p>
			<p>226 49 Lund</p>
			<p>Sverige</p>
			<p>Org.nr: 556780-4512</p>
			<a href='mailto:info@passepartoutlund.se'>info@passepartoutlund.se</a>
		</div>
	)
}

export default AboutPage