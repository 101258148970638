import { Routes, Route } from 'react-router-dom'
import './assets/scss/App.scss'

import Navigation from './components/Navigation'
import Footer from './components/Footer'

import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import HomePage from './pages/HomePage'
import NotFound from './pages/NotFound'

function App() {
  return (
		<div id="App">
			<Navigation />

			<div className="max-width page">
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/about' element={<AboutPage />} />
					<Route path='/contact' element={<ContactPage />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</div>

			<Footer />
		</div>
  );
}

export default App;
