
const ContactPage = () => {
	return (
		<div className='single-text contact'>
			<h1>Kontakta oss</h1>
			<p>Passepartout Lund AB är ledande inom försäljning och marknadsföring av mjukvara inom IoT och inbyggda system med fokus på öppen källkod. Vi har över 30 års erfarenhet och har idag samarbete med ledande leverantörer och kunder över hela världen.</p>
			
			<div className="contact__persons">
				<p>Ulrika Nemell</p>
				<p><a className="contact__email" href='mailto:ulrika@passepartoutlund.se'>ulrika@passepartoutlund.se</a></p>
				<p>Magnus Nemell</p>
				<p><a className="contact__email" href='mailto:magnus@passepartoutlund.se'>magnus@passepartoutlund.se</a></p>
			</div>
		</div>
	)
}

export default ContactPage