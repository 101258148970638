import programming_image from '../assets/images/computer-woman-web.png'

const TextImage = () => {
	return (
		<div className="text-image">
			<div className="text-image__text">
				<h2>Passepartout Lund AB</h2>
				<p>Passepartout Lund AB är ledande inom försäljning och marknadsföring av mjukvara inom IoT och inbyggda system med fokus på öppen källkod. Vi har över 30 års erfarenhet och har idag samarbete med ledande leverantörer och kunder över hela världen.</p>
			</div>
			<div className="text-image__image">
				<img src={programming_image} alt="programmingimage" />
			</div>
		</div>
	)
}

export default TextImage